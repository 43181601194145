















































































































































import { Vue, Component } from "vue-property-decorator";
import {
  MetaModule as metaStore,
  VehicleEntryModule as entryStore,
} from "@/store/modules";
import { AdminRouter } from "@/utils/routePathContsant";

@Component({
  components: {},
})
export default class VehicleScheduleClass extends Vue {
  entryList: any[] = [];
  filter: { page: number; vehicleNumber?: number } = {
    page: 1,
  };

  get AdminRouter() {
    return AdminRouter;
  }

  async created() {
    metaStore.loadVehicleListDD();
    this.search(this.filter);
  }

  get VehicleList() {
    return metaStore.vehicleList;
  }

  edit(id: any) {
    this.$router.push(AdminRouter.VehicleScheduleCreate + "?id=" + id);
  }

  async filterGpsServiceProvider() {
    this.filter.page = 1;
    this.search(this.filter);
  }
  async clearFilter() {
    this.filter = {
      page: 1,
    };
    this.search(this.filter);
  }
  submit(e: any) {
    entryStore.createVehicleEntry();
  }

  async onPageChange(val: number) {
    this.filter.page = val;
    this.search(this.filter);
  }

  async search(filter: any) {
    await entryStore.loadAllVehicleEntry(filter);
    this.entryList = entryStore.vehicleEntryList;
  }
}
